export const apiFetchInternalStatusHistory = trackingNumber => ({
  url: `/v2/package/status/${trackingNumber}`,
  method: 'GET',
});

export const fetchItemsInWaybill = tnumber => ({
  url: `/v2/package/${tnumber}/items`,
  method: 'GET',
});

export const fetchItemsViews = (reference, itemId) => ({
  url: `/v2/items/${reference}/${itemId}`,
  method: 'GET',
});

export const fetchItemsViewsByReference = reference => ({
  url: `/v2/items/${reference}`,
  method: 'GET',
});

export const fetchItemsAPI = {
  url: '/v2/items',
  method: 'POST',
};

export const exportItemsAPI = {
  url: '/v2/items/export',
  method: 'POST',
};

export const itemTrackerTaggingAPI = {
  url: '/v2/items/status',
  method: 'PUT',
};

export const itemTrackerByBarcode = (barcodeNumber, status) => ({
  url: `/v2/package/validate/item/${barcodeNumber}?status=${status}&item_id=true`,
  method: 'GET',
});

export const itemTrackerByReferenceAPI = (referenceNumber, status) => ({
  url: `/v2/package/validate/item/${referenceNumber}?status=${status}`,
  method: 'GET',
});

export const itemTrackerByTrackinNumberAPI = (trackingNumber, status) => ({
  url: `/v2/package/validate/package/${trackingNumber}?status=${status}`,
  method: 'GET',
});

export const generateItemBarcode = {
  url: '/v2/items/print',
  method: 'POST',
  responseType: 'arraybuffer',
};

export const backlogsDashboard = (params, branch) => {
  let url = `/v2/dashboard/branch-backlog?from=${params.from}&to=${params.to}&branch=${JSON.stringify(branch)}`;

  if (params.clients) {
    url += `&clients=${JSON.stringify(params.clients)}`;
  }

  return ({
    url,
    method: 'GET',
  });
};

export const backlogsDashboardDownload = (params) => {
  let url = `/v2/dashboard/branch-backlog/download?from=${params.from}&to=${params.to}&branch=${JSON.stringify(params.branch)}&reference=${params.reference}`;

  if (params.clients) {
    url += `&clients=${JSON.stringify(params.clients)}`;
  }

  return ({
    url,
    method: 'GET',
  });
};

export const backlogsDashboardDownloadOverall = (params) => {
  let url = `/v2/dashboard/branch-backlog/download/all?from=${params.from}&to=${params.to}&branch=${JSON.stringify(params.branch)}`;

  if (params.clients) {
    url += `&clients=${JSON.stringify(params.clients)}`;
  }

  return ({
    url,
    method: 'GET',
  });
};

export const volumeTrendAPI = {
  url: '/v2/dashboard/package/volume',
  method: 'GET',
};

export const kpiDashboard = {
  url: '/v2/dashboard/kpi',
  method: 'GET',
};

export const kpiDashboardDownload = {
  url: '/v2/dashboard/kpi/download',
  method: 'GET',
};

export const cashierRemittanceList = page => ({
  url: `/web/xde-remittance/${page}`,
  method: 'POST',
});

export const cashierRemittanceListFinalize = page => ({
  url: `/web/xde-remittance/${page}`,
  method: 'PUT',
});

export const billingList = page => ({
  url: `/web/billing/${page}`,
  method: 'POST',
});

// - Reused endpoints -
export const apiFetchList = page => ({
  url: `/web/${page}/list`,
  method: 'POST',
});

export const apiFetchOptions = type => ({
  url: `/web/options/${type}`,
  method: 'GET',
});

export const apiFetchOptionsLike = (type, string, filter = '') => ({
  url: `/web/options/${type}?filter=${string}${filter}`,
  method: 'GET',
});

export const apiFetchTrackingNumberByStatus = (status, trackingNumber) => ({
  url: `/web/package/validate/${status}/${trackingNumber}`,
  method: 'GET',
});

export const apiUpdateTrackingNumberStatus = trackingNumber => ({
  url: `/web/package/status/${trackingNumber}`,
  method: 'POST',
});

export const apiFetchDispatchTrackingNumber = (type, trackingNumber) => ({
  url: `/web/dispatch/package/${type}/${trackingNumber}`,
  method: 'GET',
});

export const apiFetchDispatchList = {
  url: '/web/dispatch/list/get',
  method: 'POST',
};

export const apiFetchPickupList = {
  url: '/web/pickup/list/get',
  method: 'POST',
};

export const apiFetchPickupDetails = pickupId => ({
  url: `/web/pickup/${pickupId}`,
  method: 'GET',
});

export const apiFetchTransimittalTrackingNumberByType = (
  type,
  trackingNumber,
) => ({
  url: `/web/transmittal/package/${type}/${trackingNumber}`,
  method: 'GET',
});

export const apiFetchTransimittalRefused = trackingNumber => ({
  url: `/web/package/status/${trackingNumber}`,
  method: 'POST',
});

export const apiSubmitTransmittal = {
  url: '/web/transmittal',
  method: 'POST',
};

export const apiCreateDispatch = {
  url: '/web/dispatch',
  method: 'POST',
};

export const apiUpdateDispatch = dispatchId => ({
  url: `/web/dispatch/${dispatchId}`,
  method: 'POST',
});

export const apiFetchDispatchDetails = dispatchId => ({
  url: `/web/dispatch/${dispatchId}`,
  method: 'GET',
});

export const apiDeleteDispatch = dispatchId => ({
  url: `/web/dispatch/${dispatchId}`,
  method: 'DELETE',
});
export const apiDeleteShipperDriver = id => ({
  url: `/web/shipper-driver/${id}`,
  method: 'DELETE',
})
export const apiFetchShipmentProviders = {
  url: '/web/carrier/selections',
  method: 'GET',
};

// - Reused endpoints -

// - Users endpoints -
export const apiFetchUserList = {
  url: '/v2/internal/user',
  method: 'GET',
};

export const apiCreateUser = {
  url: '/v2/internal/user',
  method: 'POST',
};

export const apiFetchUserDetails = userId => ({
  url: `/v2/internal/user/${userId}`,
  method: 'GET',
});

export const apiUpdateUser = userId => ({
  url: `/v2/internal/user/${userId}`,
  method: 'PUT',
});

export const apiResetPassword = userId => ({
  url: `/v2/internal/user/${userId}/password`,
  method: 'PUT',
});

export const apiValidateUsername = string => ({
  url: `/v2/internal/user/username/${string}`,
  method: 'GET',
});
// - Users endpoints -

// - Vehicle endpoints -
export const apiCreateVehicle = {
  url: '/web/vehicle',
  method: 'POST',
};

export const apiUpdateVehicle = vehicleId => ({
  url: `/web/vehicle/${vehicleId}`,
  method: 'PUT',
});
// - Vehicle endpoints

// - Outsource endpoints -
export const apiCreateOutsource = {
  url: '/web/outsource',
  method: 'POST',
};

export const apiUpdateOutsource = outsourceId => ({
  url: `/web/outsource/${outsourceId}`,
  method: 'PUT',
});
// - Outsource endpoints -

// - Client endpoints -
export const apiCreateClient = {
  url: '/web/client',
  method: 'POST',
};
export const apiCreateShipperDriver = {
  url: '/web/shipper-driver',
  method: 'POST',
};
export const apiUpdateClient = clientId => ({
  url: `/web/client/${clientId}`,
  method: 'PUT',
});

export const apiCreateClientStatusMap = {
  url: '/v2/client/status-map',
  method: 'POST',
};

export const apiFetchStatusMapList = {
  url: 'v2/client/status-map',
  method: 'GET',
};

export const apiFetchClientStatusMap = clientId => ({
  url: `v2/client/${clientId}/status-map`,
  method: 'GET',
});

export const apiUpdateClientStatusMap = statusMapId => ({
  url: `v2/client/status-map/${statusMapId}`,
  method: 'PUT',
});

export const apiDeleteClientStatusMap = statusMapId => ({
  url: `v2/client/status-map/${statusMapId}`,
  method: 'DELETE',
});

// - Client endpoints -

// - Customer Pickup endpoints -
export const apiFetchCustomerPickupTrackingNumber = trackingNumber => ({
  url: `/web/customer-pickup/package/${trackingNumber}`,
  method: 'GET',
});

export const apiFetchCustomerPickupDetails = pickupId => ({
  url: `/web/customer-pickup/${pickupId}`,
  method: 'GET',
});

export const apiCreateCustomerPickup = {
  url: '/web/customer-pickup',
  method: 'POST',
};

export const apiUpdateCustomerPickup = pickupId => ({
  url: `/web/pickup/${pickupId}`,
  method: 'POST',
});
// - Customer Pickup endpoints -

// - Merchant Pickup endpoints -
export const apiFetchMerchantPickupTrackingNumber = trackingNumber => ({
  url: `/web/pickup/package/merchant/${trackingNumber}`,
  method: 'GET',
});

export const apiFetchMultiMerchantPickupTrackingNumber = trackingNumber => ({
  url: `/web/pickup/package/multi-merchant/${trackingNumber}`,
  method: 'GET',
});

export const apiFetchTransmittalList = {
  url: '/web/transmittal/list/get',
  method: 'POST',
};

export const apiCreateMerchantPickup = {
  url: '/web/pickup',
  method: 'POST',
};

export const apiFetchMerchantPickupDetails = pickupId => ({
  url: `/web/pickup/${pickupId}`,
  method: 'GET',
});

export const apiFetchTransmittalDetails = transmittalId => ({
  url: `/web/transmittal/${transmittalId}`,
  method: 'GET',
});

export const apiUpdateMerchantPickup = pickupId => ({
  url: `/web/pickup/${pickupId}`,
  method: 'POST',
});

export const billingListViews = (page, id) => ({
  url: `/web/${page}/${id}`,
  method: 'GET',
});

export const printManifest = printID => ({
  url: `/web/dispatch/print/${printID}`,
  method: 'GET',
});

export const printPickupManifest = printID => ({
  url: `/web/pickup/print/${printID}`,
  method: 'GET',
});
// - Merchant Pickup endpoints -

// - Waybill endpoints -
export const apiCreateWaybill = {
  url: '/web/package',
  method: 'POST',
};
//generate Email OTP
export const apiGenerateOtpEmail = {
  url: '/web/generateotpemail',
  method: 'POST',
};
export const apiFetchWaybillList = {
  url: '/web/package/list',
  method: 'POST',
};

export const apiPrintWaybillList = {
  url: '/web/package/generate-waybills',
  responseType: 'arraybuffer',
  method: 'POST',
};

export const apiExportWaybillItems = {
  url: '/web/package/items',
  method: 'POST',
};

export const apiFetchWaybillDetails = trackingNumber => ({
  url: `/web/package/details/${trackingNumber}`,
  method: 'GET',
});

export const apiCreateUploadTemplate = {
  url: '/v2/package/template',
  method: 'POST',
  responseType: 'arraybuffer',
};

export const apiValidateWaybillUpload = {
  url: '/v2/package/validate/upload',
  method: 'POST',
};

export const apiUploadWaybill = {
  url: '/v2/package/upload',
  method: 'POST',
};

export const apiExportWaybill = {
  url: '/v2/package/export',
  method: 'POST',
  responseType: 'arraybuffer',
};

export const apiOldUploadWaybill = {
  url: '/web/package/import',
  method: 'POST',
};

// - Waybill endpoints -

// - Tools endpoints -
export const apiFetchGroupTrackingNumber = trackingNumber => ({
  url: `/v2/package/${trackingNumber}/group`,
  method: 'GET',
});

export const apiValidateDimweightFile = () => ({
  url: '/v2/package/dimweight/validate',
  method: 'POST',
});

export const apiUpdateDimweightDimensions = () => ({
  url: '/v2/package/dimweight/update',
  method: 'PUT',
});

export const apiUpdateSingleDimweightDimensions = () => ({
  url: '/v2/package/dimweight/single/update',
  method: 'PUT',
});

export const apiFetchPortClusterList = () => ({
  url: '/v2/port-cluster/list',
  method: 'GET',
});

export const apiDeletePortCluster = id => ({
  url: `/v2/port-cluster/${id}`,
  method: 'DELETE',
});

export const apiUpdatePortCluster = id => ({
  url: `/v2/port-cluster/update/${id}`,
  method: 'PUT',
});

export const apiCreatePortCluster = () => ({
  url: '/v2/port-cluster/create',
  method: 'POST',
});

export const apiValidateMassCreateUpload = () => ({
  url: '/v2/port-cluster/validate/create',
  method: 'POST',
});

export const apiMassCreateUpload = () => ({
  url: '/v2/port-cluster/mass/create',
  method: 'POST',
});

export const apiValidateMassUpdateUpload = () => ({
  url: '/v2/port-cluster/validate/update',
  method: 'POST',
});

export const apiMassUpdateUpload = () => ({
  url: '/v2/port-cluster/mass/update',
  method: 'PUT',
});

export const apiFetchPortClusterPhilippineLocationType = type => ({
  url: `/v2/port-cluster/philippine-location/${type}`,
  method: 'GET',
});

export const apiFetchPortClusterPhilpiineLocationTypeLike = (type, string) => ({
  url: `/v2/port-cluster/philippine-location/${type}/like/${string}`,
  method: 'GET',
});

export const apiFetchPhilippineLocationType = endpoint => ({
  url: `/v2/port-cluster/${endpoint}`,
  method: 'GET',
});

export const apiFetchPortClusterLocationType = type => ({
  url: `/v2/port-cluster/${type}`,
  method: 'GET',
});

export const apiFetchPortClusterLocationTypeWhereIn = type => ({
  url: `/v2/port-cluster/${type}/where`,
  method: 'GET',
});

export const apiFetchPhilippineLocationTypeLike = (endpoint, string) => ({
  url: `/v2/port-cluster/${endpoint}/like/${string}`,
  method: 'GET',
});

// - Shipper endpoints -

export const apiFetchShipperDetails = id => ({
  url: `/v2/shipper/${id}`,
  method: 'GET',
});

export const apiFetchShipperOptionsLike = string => ({
  url: `/v2/shipper/options/like/${string}`,
  method: 'GET',
});

export const apiFetchShipperOptions = {
  url: '/v2/shipper/options',
  method: 'GET',
};

// - Shipper endpoints -

// - Dispatch endpoints -

export const apiExportDispatch = id => ({
  url: `/v2/dispatch/${id}/export`,
  method: 'GET',
  responseType: 'arraybuffer',
});

export const apiExportPickupDispatch = id => ({
  url: `/v2/dispatch/pickup/${id}/export`,
  method: 'GET',
  responseType: 'arraybuffer',
});

// - Dispatch endpoints -

// START Dashboard Pickup Calendar Endpoints
export const apiGetPickupCalendar = params => ({
  url: `/v2/dashboard/package/pickup/calendar?client_id=${params.clientId}&pickup_from=${params.pickup_from}&pickup_to=${params.pickup_to}`,
  method: 'GET',
});

export const apiDownloadPickupCalendar = params => ({
  url: `/v2/dashboard/package/pickup/calendar/${params.clientId}/${params.pickupDate}/${params.type}`,
  method: 'GET',
  responseType: 'arraybuffer',
});

// END Dashboard Pickup Calendar Endpoints


// START Dashboard Fleet Performance

export const apiGetFleetPerfFM = {
  url: '/v2/dashboard/user/courier/performance/firstmile',
  method: 'GET',
};

export const apiGetFleetPerfLM = {
  url: '/v2/dashboard/user/courier/performance/lastmile',
  method: 'GET',
};

// END Dashboard Fleet Performance
